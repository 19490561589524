import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { IState } from './auth.state';
import { getAuthenticated, getRole, getUserName } from './auth.selector';
import { login, logout, changeUserProfile } from './auth.action';

@Injectable({
    providedIn: 'root'
})
export class AuthFacade {
    isAuthticated$ = this.store.pipe(select(getAuthenticated));
    role$ = this.store.pipe(select(getRole));
    userName$ = this.store.pipe(select(getUserName));

    constructor(private store: Store<IState>) { }

    /**
     * login
     */
    login(userId: string, password: string, roles: string[]): void {
        this.store.dispatch(login({ userId, password, roles }));
    }

    /**
     * logout
     */
    logout(): void {
        this.store.dispatch(logout({}));
    }

    changeProfileName(payload: string): void {
        this.store.dispatch(changeUserProfile({ payload }));
    }
}
