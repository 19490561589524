<app-non-home-nav-bar logoImagePath="assets/logo/new_home_logo_2.png"></app-non-home-nav-bar>

<!-- grey line seperator -->
<hr style="background-color: darkgray;">
<!-- <div class="banner-img">
    <div class="container-fluid banner-background-color">
      <div class="container">
        <div class="row">
          <div class="banner-title">

          </div>
        </div>
      </div>
    </div>
  </div> -->

<div class="container dashboard">
  <div class="m-0" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">

    <!-- primeng Menu -->
    <div>
      <div id="sidebar">
        <p-menu [model]="menuItems" (click)="onActiveMenu($event)"></p-menu>

        <!-- <ng-container *ngFor="let dlink of dLinks">
              <a class="list-group-item collapsed box" data-parent="#sidebar" [routerLink]="[dlink.link]" routerLinkActive="active"> <i
                  [className]="dlink.icon"></i>
                <span class="hidden-sm-down"> {{dlink.label}}</span>
              </a>
            </ng-container> -->
      </div>

      <!--  <ng-material-multilevel-menu [configuration]='config' [items]='dLinks' (selectedItem)="selectedItem($event)" (selectedLabel)="selectedLabel($event)">
        </ng-material-multilevel-menu> -->
    </div>

    <!-- right side -->
    <div class="p-0" fxFlex="100">
      <div class="dashboard-child-component-header">
        <h3 class="mb-0">{{childComponentTitle}}</h3>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-property-listing-at-home></app-property-listing-at-home>
