import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

    constructor() { }

    logError(message: string, stack?: string): void {
        // Send errors to server here
        console.log('LoggingService - Message:', message);
        if (stack) {
            console.log('LoggingService - Stack:', stack);
        }
    }

    logClientStack(error: Error): void {
        console.log('Client-side error stack:', error.stack);
    }
}
