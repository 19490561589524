import { MenuId } from 'src/app/constants/menu-id.const';
import { Role } from 'src/app/constants/role';

export const profileMenu = {
    label: 'Profile',
    link: 'profile-info',
    icon: 'far fa-user-circle',
    role: '',
};

export const allRequestMenu = {
    label: 'All Requests',
    link: `request/${Role.agent}`,
    icon: 'far fa-calendar-alt',
    role: '',
};

export const myRequestMenu = {
    label: 'My Requests',
    link: `request/${Role.tenant}`,
    icon: 'far fa-calendar-alt',
    role: '',
};

export const visitMenu = {
    label: 'Visits',
    link: 'visit',
    icon: 'far fa-clock',
    role: '',
};

export const addPropertyMenu = {
    label: 'Add Property',
    link: 'property/add',
    icon: 'fas fa-plus-circle',
    role: '',
};

export const ownerPropertyListMenu = {
    label: 'My Property Listing',
    link: `property-list/${MenuId.ownerPropertyList}`,
    icon: 'fas fa-list',
    role: '',
};

export const agentPropertyListMenu = {
    label: 'Managing Property Listing',
    link: `property-list/${MenuId.agentPropertyList}`,
    icon: 'fas fa-list',
    role: '',
};

export const adminPropertyListMenu = {
    label: 'All Property Listing',
    link: `property-list/${MenuId.adminPropertyList}`,
    icon: 'fas fa-list',
    role: '',
};

export const paymentMenu = {
    label: 'Property Payment',
    link: 'property-payment',
    icon: 'fas fa-dollar-sign',
    role: '',
};

export const tenantBookingMenu = {
    label: 'My Booking List (Tenant)',
    link: `booking/${MenuId.tenantBookingList}`,
    icon: 'fas fa-book',
    role: '',
};

export const ownerBookingMenu = {
    label: 'My Booking List (Owner)',
    link: `booking/${MenuId.ownerBookingList}`,
    icon: 'fas fa-book',
    role: '',
};

export const agentBookingMenu = {
    label: 'Managing Booking List',
    link: `booking/${MenuId.agentBookingList}`,
    icon: 'fas fa-book',
    role: '',
};

export const adminBookingMenu = {
    label: 'All Booking List',
    link: `booking/${MenuId.adminBookingList}`,
    icon: 'fas fa-book',
    role: '',
};

export const userMenu = {
    label: 'User List',
    link: 'user-maintain',
    icon: 'fas fa-user',
    role: '',
};

export const config = {
    paddingAtStart: true,
    classname: 'custom-menu',
    fontColor: '#ffffff',
    // fontColor: `rgb(8, 54, 71)`,
    // backgroundColor: '#f28401',
    listBackgroundColor: '#f28401',
    selectedListFontColor: `red`,
    collapseOnSelect: true,
    // interfaceWithRoute: true,
};
