import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { IAppConfig } from 'src/app/app-config.model';

if (environment.production) {
    enableProdMode();
}

async function main(): Promise<any> {
    try {
        const data = await fetch(`assets/config/config.${environment.name}.json`);
        const config: IAppConfig = await data.json();
        console.error(config);

        await platformBrowserDynamic([
            { provide: BASE_API_URL_TOKEN, useValue: config },
        ]).bootstrapModule(AppModule);

    } catch (error) {
        console.error(error);
    }
}

main();
