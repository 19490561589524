<div id="page-not-fount">
  <div class="container pr-0">
    <div class="pageError">
      <img src="assets/logo/pagenotfound.png"  alt="">
      <h2> 404</h2>
      <h4>Oops! Page Not Found</h4>
      <button mat-raised-button class="btn" (click)="gohome()"><i class="fa fa-home"></i> Go to Home </button>
    </div>
  </div>
</div>
