import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PropertyListingAtHomeComponent } from 'src/app/components/home/property-listing-at-home/property-listing-at-home.component';

@NgModule({
    declarations: [
        PropertyListingAtHomeComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        PropertyListingAtHomeComponent,
    ]
})
export class AddPropertyButtonModule { }
