import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

// https://github.com/angular/angular/blob/master/packages/common/http/src/xhr.ts#L18
const XSSI_PREFIX = /^\)\]\}',?\n/;

@Injectable()
export class JsonDateInterceptor implements HttpInterceptor {
    /**
     * Custom http request interceptor for date object
     * @param req HttpRequest object
     * @param next HttpHandler handler
     */
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.responseType !== 'json') {
            return next.handle(req);
        }
        // convert to responseType of text to skip angular parsing
        req = req.clone({
            responseType: 'text'
        });

        return next.handle(req).pipe(map(event => {
            // Pass through everything except for the final response.
            if (!(event instanceof HttpResponse)) {
                return event;
            }

            return this.processJsonResponse(event);
        }));
    }

    /**
     * Parse the json body using custom revivers.
     * @param res HttpResponse object
     */
    private processJsonResponse(res: HttpResponse<string>): HttpResponse<any> {
        let body = res.body;
        if (typeof body === 'string') {
            const originalBody = body;
            body = body.replace(XSSI_PREFIX, '');
            try {
                body = body !== '' ? JSON.parse(body, (key: any, value: any) => this.reviveUtcDate(key, value)) : null;
            } catch (error) {
                // match https://github.com/angular/angular/blob/master/packages/common/http/src/xhr.ts#L221
                throw new HttpErrorResponse({
                    error: { error, text: originalBody },
                    headers: res.headers,
                    status: res.status,
                    statusText: res.statusText,
                    url: res.url || undefined,
                });
            }
        }
        return res.clone({ body });
    }

    /**
     * Detect a date string and convert it to a date object.
     * @param key key
     * @param value value
     */
    private reviveUtcDate(key: any, value: any): any {
        if (typeof value !== 'string') {
            return value;
        }
        if (value === '0001-01-01T00:00:00') {
            return null;
        }

        const match = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/.exec(value);
        if (!match) {
            return value;
        }

        // if there is no 'Z' at the end, we need to add it to represent UTC (because server is always saved as UTC)
        let utcString: string;
        if (value.slice(-1) === 'Z') {
            utcString = value;
        } else {
            utcString = value + 'Z';
        }

        const utc = new Date(utcString);
        return utc;
    }
}
