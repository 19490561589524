<section id="footer-section">
  <div class="container text-white">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" class="p-3">

      <!-- Quick links -->
      <div class="quick-links">
        <h4 class="f400">Quick links</h4>
        <ul>
          <!-- <li>
              <a href="{{doc_link}}/Info_to_Owners.pdf" target="_blank" alt="Owner Information">Owner Information</a>
            </li> -->
          <li>
            <a href="/#/property-list">Find a property</a>
          </li>
          <li>
            <a href="{{doc_link}}/Info_for_Property_Owners.pdf" target="_blank" alt='Owner information and mandate'>Owner information and mandate</a>
          </li>
          <li>
            <a href="{{doc_link}}/Info_for_Tenants.pdf" target="_blank" alt='Guest information'>Guest information</a>
          </li>
          <li>
            <a href="{{doc_link}}/Tenant_application_form_fillable_Nov2021.docx" target="_blank" alt='Guest application'>Guest application</a>
          </li>
          <li>
            <a href="{{doc_link}}/Description_for_Rent2Buy_House_on_875.pdf" target="_blank" alt='Guest application'>RENT TO BUY FINANCE</a>
          </li>

          <!--
          <li>
            <a href="{{doc_link}}/Owner_Application.pdf" target="_blank" alt='Owner Application'>Owner Application</a>
          </li>
          <li>
            <a href="{{doc_link}}/Guest_Application.pdf" target="_blank" alt='Guest Application'>Guest Application</a>
          </li>

          <li>
            <a href="{{doc_link}}/Info_to_Owners.pdf" target="_blank" alt='Guest Application'>Information to Owner</a>
          </li>

          <li>
            <a href="{{doc_link}}/Info_to_Guests.pdf" target="_blank" alt='Guest Application'>Information to Guest</a>
          </li>

          <li>
            <a href="{{doc_link}}/RentalAgreement.pdf" target="_blank" alt='Guest Application'>Rental Agreement</a>
          </li>
        -->
        </ul>
      </div>

      <!-- Contact Details -->
      <div>
        <h4 class="f400">Contact Details</h4>
        <div><span>Address: 65-67 Birkenhead Avenue, Birkenhead, Auckland 0746</span></div>
        <div><span>Phone: 09 4 73 6825 / 09 4 RENTAL</span></div>
        <div><span>Or text: Name + Email + Reason to: 027 9 736825 / 027 9 RENTAL</span></div>
        <div><span>Email: <a href="mailto:ask.tgh@gmail.com">ask.tgh@gmail.com</a></span></div>
      </div>

      <!-- Locate Us -->
      <div>
        <div class="locate-us" style="max-width: 300px;">
          <h4 class="f400">Locate Us</h4>
          <div class="imgBox">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3194.321637549001!2d174.72385921529022!3d-36.810811879946336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d38f3f9bb4353%3A0xdea0a1b8a9ef3ca3!2s65%20Birkenhead%20Avenue%2C%20Birkenhead%2C%20Auckland%200626!5e0!3m2!1sen!2snz!4v1639429520842!5m2!1sen!2snz" width="360" height="200" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="copy-right-section">
  <div class="container text-white f400 text-center mb-0">
    <span> &copy; TGH version 0.1.27 on 22.02.2022 </span> <span>| </span>
    <!-- <span> &copy; TGH version 0.1.27 on 22.02.2022 </span> <span>| </span> -->
    <span> Privacy Policy</span> <span>| </span>
    <span> Disclaimer and Disclosures </span> <span>| </span>
    <span> User Terms </span> <span>| </span>
  </div>
</section>
