import { createAction, props } from '@ngrx/store';

export const login = createAction(
    '[Login Page] Login',
    props<{ userId: string; password: string, roles: string[] }>()
);

export const logout = createAction(
    '[Login Page] Logout',
    props<{}>()
);

export const changeUserProfile = createAction(
    '[Login Page] User Profile',
    props<{ payload: string }>()
);
