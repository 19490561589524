import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { AppAlertService } from 'src/app/_services/app-alert.service';
import { ErrorService } from 'src/app/_services/error.service';
import { LoggingService } from 'src/app/_services/logging.service';
import { CustomErrorResponse } from 'src/app/_helpers/custom-error-response';
import { NoErrorResponse } from './no-error-response';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(pError: any): void {
        if (pError instanceof NoErrorResponse) {
            // For some errors, we do not want to show message, then throw NoErrorResponse and do nothing here and return
            return;
        }

        const errorService = this.injector.get(ErrorService);
        const logger = this.injector.get(LoggingService);
        const alert = this.injector.get(AppAlertService);

        let message: string;
        let stackTrace: string;
        if (pError instanceof HttpErrorResponse) {
            // Server error
            message = errorService.getServerErrorMessage(pError);
            // stackTrace = errorService.getServerErrorStackTrace(error);
            // To see if pError.error has properties - "status=400", "traceId", "title", "errors"
            if (pError.error instanceof ProgressEvent) {
                // "Failed to load resource: net::ERR_CONNECTION_REFUSED" happens for this case at least.
                alert.error(message, `Response Error (HTTP Error: ${pError.status})`);
            // } else if (pError.error instanceof Object) {
            //     alert.error(pError.message, `Response Error`);
            } else if (pError.error) {
                // const errorJsonObj = JSON.parse(pError.error);
                const errorObj = pError.error;
                if (errorObj.status === 400) {
                    // If validation happens in API, we will have properties "status=400", "traceId", "title", "errors" in pError.errors
                    let description = '';
                    for (const prop in errorObj.errors) {
                        if (prop) {
                            description += errorObj.error.errors[prop][0] + '\n';
                        }
                    }

                    alert.error(description, `${errorObj.title} (HTTP Error: ${errorObj.status})`);
                } else {
                    alert.error(pError.message, `${pError.name} (HTTP Error: ${pError.status}-${pError.statusText})`);
                }
            } else {
                alert.error(message, `Response Error (HTTP Error: ${pError.status})`);
            }
        } else if (pError instanceof CustomErrorResponse) {
            const customError = pError as CustomErrorResponse;
            message = `[Code] "${customError.statusCode}" / [Title] ${customError.title} / [Description] ${customError.description}`;
            alert.error(customError.description, `${customError.title} (Error Code: ${customError.statusCode})`);
        } else if (pError.promise && pError.rejection) {
            // Promise rejection wrapped by zone.js
            pError = pError.rejection;
            message = errorService.getClientErrorMessage(pError);
            stackTrace = errorService.getClientStack(pError);
            alert.error(message, 'Error');
        } else {
            // Client Error
            message = errorService.getClientErrorMessage(pError);
            stackTrace = errorService.getClientStack(pError);
            alert.error(message, 'Client Error');
        }

        // Always log errors
        logger.logError(message, stackTrace);
    }
}
