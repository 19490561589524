import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { featureName } from 'src/app/store/auth/auth.state';
import { reducer } from 'src/app/store/auth/auth.reducer';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MenuModule } from 'primeng/menu';

import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { HttpLoaderInterceptor } from './_helpers/http-loader.interceptor';
import { TimeZoneInterceptor } from './_helpers/time-zone.interceptor';
import { ServerErrorInterceptor } from './_helpers/server-error.interceptor';
import { JsonDateInterceptor } from './_helpers/json-date.interceptor';
import { GlobalErrorHandler } from './_helpers/global.error-handler';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from 'src/app/components/shared/spinner/spinner.component';
import { PageNotFoundComponent } from 'src/app/components/shared/page-not-found/page-not-found.component';

import { FooterComponent } from 'src/app/components/_layout/footer/footer.component';
import { NonHomeNavBarComponent } from 'src/app/components/_layout/nav-bar/non-home-nav-bar.component';
import { CommonLayoutComponent } from 'src/app/components/_layout/common-layout/common-layout.component';
import { AddPropertyButtonModule } from 'src/app/components/home/property-listing-at-home/add-property-button.module';
import { DashboardPanelComponent } from 'src/app/components/dashboard/dashboard-panel/dashboard-panel.component';

import { SortablejsModule } from 'ngx-sortablejs';

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        SpinnerComponent,
        FooterComponent,
        NonHomeNavBarComponent,
        CommonLayoutComponent,
        DashboardPanelComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FlexLayoutModule,
        NgbModule,
        StoreModule.forRoot({}),
        StoreModule.forFeature(featureName, reducer),
        EffectsModule.forRoot([]),
        ToastrModule.forRoot({
            timeOut: 5000,
            preventDuplicates: true,
        }),

        AppRoutingModule,
        MenuModule,
        ProgressSpinnerModule,
        AddPropertyButtonModule,
        SortablejsModule.forRoot({animation: 500,direction: 'horizontal'}),
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimeZoneInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
