import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IState, featureName } from './auth.state';

/**
 * Selectors
 */
const getState = createFeatureSelector<IState>(featureName);

export const getAuthenticated = createSelector(getState, state => state.isAuthenticated);
export const getRole = createSelector(getState, state => state.role);
export const getUserName = createSelector(getState, state => state.displayedUserName);
