import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// import { AuthService } from 'angularx-social-login';
// import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';

import { UserViewModel } from 'src/app/models/user-view.model';
import { getUserRoles } from 'src/app/utls/common-functions';
import { AuthFacade } from 'src/app/store/auth/auth.facade';
import { ResetPasswordModel } from 'src/app/models/reset-password.model';
import { ApiHttpService } from './api-http.service';

@Injectable({
    providedIn: 'root'
})
export class AppAuthService {
    // patternForUserName = new RegExp('^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$');
    // patternForEmail = new RegExp('^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[_a-z0-9])+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$');
    // patternForPassword = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
    passwordMinLength = 6;

    isSocialUser$ = new BehaviorSubject(false);

    // constructor(private http: HttpClient, private socialauthService: SocialAuthService, private authFacade: AuthFacade) {
    constructor(private http: ApiHttpService, private authFacade: AuthFacade) {
        // check social user logged in
        // this.socialauthService.authState.subscribe((user) => {
        //     console.log('Social user:', user);
        //     if (user) {
        //         this.setUser(user, 'social');
        //     }
        // });
    }

    // user login
    login(formData): Observable<any> {
        const body = { username: formData.username, password: formData.password };
        return this.http.create('account/login', body).pipe(
            map(
                data => {
                    if (data) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('userToken', JSON.stringify(data.access_token));
                    }

                    const roles: string[] = getUserRoles();
                    this.authFacade.login(formData.username, formData.password, roles);
                }
            )
        );
    }

    setUser(user: UserViewModel, type: string): void {
        localStorage.setItem('user', JSON.stringify(user));
        console.log(user);
        this.isSocialUser$.next((type === 'social') ? true : false);

        this.authFacade.changeProfileName(user.firstName);
    }

    logout(): void {
        localStorage.removeItem('userToken');
        localStorage.removeItem('user');
        if (this.isSocialUser$.value) {
            this.isSocialUser$.next(false);
            // this.socialauthService.signOut();
        }

        this.authFacade.logout();
    }

    // get user details
    getUserInfo(): Observable<UserViewModel> {
        return this.http.get('Profile/GetProfile');
    }

    // register new user - completed
    tryRegister(data): Observable<any> {
        const body = {
            firstName: data.fname,
            lastName: data.lname,
            email: data.email,
            password: data.password,
            confirmPassword: data.c_password,
            userRoles: data.role,
            phoneNumber: data.phoneNumber,
            mandate: data.mandate,
            dob: data.dob,
            gender: data.gender,
            ethnicity: data.ethnicity,
            vaccinationStatus: data.vaccinationStatus,
            criminalHistory: data.criminalHistory,
        };

        return this.http.create('Account/Register', body);
    }

    // update user details
    updateUserInfo(data: UserViewModel): Observable<any> {
        return this.http.create('Profile/UpdateProfile', data);
    }

    // update user mandate
    updateMandate(data: UserViewModel): Observable<any> {
        return this.http.create('Profile/UpdateMandate', data);
    }

    // confirm user email
    validateUserEmail(userid: string, token: string): Observable<any> {
        const data = {
            code: token,
            userId: userid
        };

        return this.http.create('Account/ConfirmEmail', data );
    }

    // submit contact details
    submitContactEmail(data): Observable<any> {
        return this.http.create('Account/SubmitContact', data);
    }

    // forget user password step 1
    submitUserEmail(data): Observable<any> {
        return this.http.create('Account/ForgotPassword', data);
    }

    // authenticate User for reset password  step 2
    resetMypassword(data: ResetPasswordModel): Observable<any> {
        return this.http.create('Account/ResetPassword', data);
    }

    // Change Password for logined User
    changePassword(data): Observable<any> {
        return this.http.create('Account/ChangePassword', data);
    }
}

