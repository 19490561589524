import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'src/app/_guards/auth.guard';
import { PageNotFoundComponent } from 'src/app/components/shared/page-not-found/page-not-found.component';
import { CommonLayoutComponent } from 'src/app/components/_layout/common-layout/common-layout.component';
import { DashboardPanelComponent } from 'src/app/components/dashboard/dashboard-panel/dashboard-panel.component';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'home',
        loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'pagenotfound',
        component: PageNotFoundComponent,
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: [
            {
                path: 'about-us',
                loadChildren: () => import('./components/about-us/about-us.module').then(m => m.AboutUsModule)
            },
            {
                path: 'contact-us',
                loadChildren: () => import('./components/contact-us/contact-us.module').then(m => m.ContactUsModule)
            },
            {
                path: 'property-list',
                loadChildren: () => import('./components/property/property-list/property-list.module').then(m => m.PropertyListModule)
            },
            {
                path: 'property-details/:id',
                loadChildren: () => import('./components/property/property-details/property-details.module').then(m => m.PropertyDetailsModule)
            },
            {
                path: 'login',
                loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'register',
                loadChildren: () => import('./components/user-registration/register/register.module').then(m => m.RegisterModule)
            },
            {
                path: 'user-confirmation',
                loadChildren: () => import('./components/user-registration/confirmation/confirmation.module').then(m => m.ConfirmationModule)
            },
            {
                path: 'resetpassword',
                loadChildren: () => import('./components/user-registration/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
            },
        ],
    },
    {
        path: 'dashboard',
        component: DashboardPanelComponent,
        children: [
            { path: '', redirectTo: 'profile-info', pathMatch: 'full' },
            {
                path: 'profile-info',
                loadChildren: () => import('./components/dashboard/user-profile/profile-info/profile-info.module').then(m => m.ProfileInfoModule)
            },
            {
                path: 'profile-edit',
                loadChildren: () => import('./components/dashboard/user-profile/profile-edit/profile-edit.module').then(m => m.ProfileEditModule)
            },
            {
                path: 'property',
                loadChildren: () => import('./components/tgh-owner/modify-property/modify-property.module').then(m => m.ModifyPropertyModule)
            },
            {
                path: 'property-list/:menuid',
                loadChildren: () => import('./components/tgh-owner/owner-property-list/owner-property-list.module').then(m => m.OwnerPropertyListModule)
            },
            {
                path: 'property-viewing/:menuid',
                loadChildren: () => import('./components/property-viewing/property-viewing.module').then(m => m.PropertyViewingModule)
            },
            {
                path: 'membership',
                loadChildren: () => import('./components/dashboard/membership/membership.module').then(m => m.MembershipModule)
            },
            {
                path: 'property-payment',
                loadChildren: () => import('./components/tgh-owner/property-payment/property-payment.module').then(m => m.PropertyPaymentModule)
            },
            {
                path: 'request/:menuid',
                loadChildren: () => import('./components/request/request.module').then(m => m.RequestModule)
            },
            {
                path: 'booking/:menuid',
                loadChildren: () => import('./components/booking/booking.module').then(m => m.BookingModule)
            },

            {
                path: 'user-maintain',
                loadChildren: () => import('./components/user-maintain/user-maintain.module').then(m => m.UserMaintainModule)
            },
        ],
        canActivate: [AuthGuard],
    },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
