import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

@Injectable()
export class TimeZoneInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // do not add the header of 'TimeZone-Offset' when call google api
        if (!request.url.includes('https://maps.googleapis.com')) {
            request = request.clone({
                setHeaders: {
                    'TimeZone-Offset': `${new Date().getTimezoneOffset()}`
                }
            });
        }

        return next.handle(request);
    }
}
