import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from 'src/app/constants/role';
import { PropertyModel } from 'src/app/models/property.model';

export function authenticated(): boolean {
    const myRawToken: string = localStorage.getItem('userToken');
    return !!myRawToken;
}

export function getUserId(): string {
    const helper = new JwtHelperService();
    const myRawToken: string = localStorage.getItem('userToken');
    if (myRawToken) {
        const decodedToken = helper.decodeToken(myRawToken);
        return decodedToken.unique_name;
    }

    return null;
}

export function getUserRoles(): string[] {
    const helper = new JwtHelperService();
    const myRawToken: string = localStorage.getItem('userToken');
    if (myRawToken) {
        const decodedToken = helper.decodeToken(myRawToken);
        return decodedToken.role;
    }

    return [];
}

export function getSecuredFullAddress(property: PropertyModel): string {
    const userId = getUserId();
    const userRoles: string[] = getUserRoles();
    let fullAddress: string;
    if (userRoles.includes(Role.agent) || userRoles.includes(Role.admin)) {
        fullAddress = `${property.addressLine} ${property.street}, ${property.suburb}, ${property.city}`;
    } else if (userRoles.includes(Role.propertyOwner) && userId === property.userID) {
        fullAddress = `${property.addressLine} ${property.street}, ${property.suburb}, ${property.city}`;
    } else {
        fullAddress = `${property.suburb}, ${property.city}`;
    }

    if (property.postCode) {
        return `${fullAddress} ${property.postCode}`;
    } else {
        return fullAddress;
    }
}

export function getFullAddress(property: PropertyModel): string {
    const fullAddress = `${property.addressLine} ${property.street}, ${property.suburb}, ${property.city}`;

    if (property.postCode) {
        return `${fullAddress} ${property.postCode}`;
    } else {
        return fullAddress;
    }
}
