import { MenuId } from 'src/app/constants/menu-id.const';
import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import {
    config,
    profileMenu,

    addPropertyMenu,

    adminPropertyListMenu,
    agentPropertyListMenu,
    ownerPropertyListMenu,

    tenantBookingMenu,
    agentBookingMenu,

    myRequestMenu,
    allRequestMenu,

    userMenu,

    visitMenu,
    paymentMenu,
} from './dashboard-menu.model';
import { Router } from '@angular/router';

import { getUserRoles } from 'src/app/utls/common-functions';
import { Role } from 'src/app/constants/role';
import { DashboardUiService } from 'src/app/components/dashboard/dashboard-ui.service';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-dashboard-panel',
    templateUrl: './dashboard-panel.component.html',
    styleUrls: ['./dashboard-panel.component.scss']
})
export class DashboardPanelComponent implements OnInit, AfterViewChecked {
    menuItems: MenuItem[] = [];

    dLinks: any[];
    config: any = config;
    childComponentTitle: string;
    userRoles: string[];

    constructor(private router: Router, private dashboardUiService: DashboardUiService, private cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.childComponentTitle = localStorage.getItem('childComponentTitle');
        this.dashboardUiService.childComponentTitle$.subscribe(title => this.childComponentTitle = title);

        this.initMenus();
    }

    private initMenus(): void {
        this.userRoles = getUserRoles();
        const adminItems: MenuItem = {
            label: 'Administrator',
            items: [
                { label: 'My Profile', icon: 'fas fa-user-circle', routerLink: 'profile-info'},
                { label: 'User', icon: 'fas fa-user', routerLink: 'user-maintain' },
                { label: 'All Property List', icon: 'fas fa-list', routerLink: `property-list/${MenuId.adminPropertyList}` },
                { label: 'Request', icon: 'fas fa-envelope', routerLink: `request/${MenuId.adminRequestList}` },
                { label: 'Viewing Time', icon: 'far fa-clock', routerLink: `property-viewing/${MenuId.adminViewingTimeList}` },
                { label: 'Booking', icon: 'fas fa-book', routerLink: `booking/${MenuId.adminBookingList}` },
            ]
        };

        const agentItems: MenuItem = {
            label: 'Regional Agent',
            items: [
                { label: 'Properties', icon: 'fas fa-list', routerLink: `property-list/${MenuId.agentPropertyList}` },
                { label: 'Request', icon: 'fas fa-envelope', routerLink: `request/${MenuId.agentRequestList}` },
                { label: 'Viewing Time', icon: 'far fa-clock', routerLink: `property-viewing/${MenuId.agentViewingTimeList}` },
                { label: 'Booking', icon: 'fas fa-book', routerLink: `booking/${MenuId.agentBookingList}` }
            ]
        };

        const ownerItems: MenuItem = {
            label: 'Property Owner',
            items: [
                { label: 'My Profile', icon: 'fas fa-user-circle', routerLink: 'profile-info'},
                { label: 'Add Property', icon: 'fas fa-plus-circle', routerLink: 'property/add' },
                { label: 'My Properties', icon: 'fas fa-list', routerLink: `property-list/${MenuId.ownerPropertyList}` },
                // { label: 'Viewing Time', icon: 'far fa-clock', routerLink: `property-viewing/${MenuId.ownerViewingTimeList}` },
                { label: 'Booking', icon: 'fas fa-book', routerLink: `booking/${MenuId.ownerBookingList}` },
            ]
        };

        const tenantItems: MenuItem = {
            label: 'Tenant',
            items: [
                { label: 'My Profile', icon: 'fas fa-user-circle', routerLink: 'profile-info'},
                { label: 'My Request', icon: 'fas fa-envelope', routerLink: `request/${MenuId.tenantRequestList}` },
                { label: 'My Viewing Time', icon: 'far fa-clock', routerLink: `property-viewing/${MenuId.tenantViewingTimeList}` },
                { label: 'My Booking', icon: 'fas fa-book', routerLink: `booking/${MenuId.tenantBookingList}` }
            ]
        };

        const winzItems: MenuItem = {
            label: 'WINZ',
            items: [
                { label: 'My Profile', icon: 'fas fa-user-circle', routerLink: 'profile-info'},
                { label: 'My Request', icon: 'fas fa-envelope', routerLink: `request/${MenuId.tenantRequestList}` },
                { label: 'My Viewing Time', icon: 'far fa-clock', routerLink: `property-viewing/${MenuId.tenantViewingTimeList}` },
                { label: 'My Booking', icon: 'fas fa-book', routerLink: `booking/${MenuId.tenantBookingList}` }
            ]
        };

        if (this.userRoles.includes(Role.agent)) {
            this.menuItems.push(agentItems);
        }

        if (this.userRoles.includes(Role.propertyOwner)) {
            this.menuItems.push(ownerItems);
        }

        if (this.userRoles.includes(Role.tenant)) {
            this.menuItems.push(tenantItems);
        }

        if (this.userRoles.includes(Role.admin)) {
            this.menuItems.push(adminItems);
        }

        if (this.userRoles.includes(Role.winz)) {
            this.menuItems.push(winzItems);
        }

        // // if the login user role is property owner
        // this.dLinks = [];
        // // profile link for all kinds of users
        // this.dLinks.push(profileMenu);

        // if (this.userRoles.includes(Role.agent)) {
        //     // agent managed property list
        //     this.dLinks.push(agentPropertyListMenu);

        //     // all requirements can be used for agent
        //     this.dLinks.push(allRequestMenu);

        //     // visits
        //     this.dLinks.push(visitMenu);

        //     this.dLinks.push(agentBookingMenu);

        //     this.dLinks.push(paymentMenu);
        // }

        // // Add menu - Add Property for property owner
        // if (this.userRoles.includes(Role.propertyOwner)) {
        //     // add property
        //     this.dLinks.push(addPropertyMenu);
        //     // owner property list
        //     this.dLinks.push(ownerPropertyListMenu);
        // }

        // if (this.userRoles.includes(Role.tenant)) {
        //     // my requiements for tenant
        //     this.dLinks.push(myRequestMenu);
        //     this.dLinks.push(tenantBookingMenu);
        // }

        // if (this.userRoles.includes(Role.admin)) {
        //     this.dLinks.push(adminPropertyListMenu);
        //     this.dLinks.push(userMenu);
        // }
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    /**
     * This event handler is for menu effect
     */
    onActiveMenu(event: any): void {
        let node: any;
        if (event.target.classList.contains('p-submenu-header') === true) {
            node = 'submenu';
        } else if (event.target.tagName === 'SPAN') {
            node = event.target.parentNode.parentNode;
        } else {
            node = event.target.parentNode;
        }

        if (node !== 'submenu') {
            const menuitem: HTMLCollectionOf<Element> = document.getElementsByClassName('p-menuitem');
            Array.from(menuitem).forEach(element => {
                element.classList.remove('active');
            });

            node.classList.add('active');
        }
    }
}
