import { Injectable, NgZone, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AppAlertService {
    constructor(private ngZone: NgZone, private injector: Injector) { }

    private get toastrService(): ToastrService {
        return this.injector.get(ToastrService);
    }

    success(msg: string, title?: string, isTimer?: boolean): void {
        this.ngZone.run(() => {
            this.toastrService.success(msg, title, { progressBar: isTimer });
        });
    }

    error(msg: string, title?: string, isTimer?: boolean): void {
        this.ngZone.run(() => {
            this.toastrService.error(msg, title, { progressBar: isTimer });
        });
    }

    warn(msg: string, title?: string, isTimer?: boolean): void {
        this.ngZone.run(() => {
            this.toastrService.warning(msg, title, { progressBar: isTimer });
        });
    }
}
