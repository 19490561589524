<div *ngIf="this.isActive">
<div *ngIf="!(isAuthenticated$ | async) || (role$ | async).includes('Property Owner')">
  <div class="submit-property-box text-white">
    <div class="container">
      <div class="row my-2">
        <div class="col-md-6 m-auto">
          <h3 class="mb-0">Do you want your property to be listed here?</h3>
        </div>
        <div class="col-md-2 m-auto" style="text-align: right;">
          <h3 class="mb-0">Click Here <i class="fa fa-hand-point-right"></i></h3>
        </div>
        <div class="col-md-4" style="text-align: left;">
          <button mat-raised-button style="font-size: 24px; background: #000;" (click)="onAddProperty()">List your property</button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
