import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthFacade } from 'src/app/store/auth/auth.facade';
import { LocationStrategy } from '@angular/common';

@Component({
    selector: 'app-property-listing-at-home',
    templateUrl: './property-listing-at-home.component.html',
    styleUrls: ['./property-listing-at-home.component.scss']
})
export class PropertyListingAtHomeComponent implements OnInit {
    isAuthenticated$ = this.authFacade.isAuthticated$;
    role$: Observable<string[]> = this.authFacade.role$;
    isActive = false;

    constructor(private router: Router, private authFacade: AuthFacade, private url:LocationStrategy) { }

    ngOnInit(): void {
        this.isActive = !this.url.path().includes('dashboard');
    }

    onAddProperty(): void {
        if (localStorage.getItem('user')) {
            this.router.navigate(['/dashboard/property/add']);
        } else {
            // need to login to add a property if unlogin
            this.router.navigate(['login']);
        }
    }
}
