import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthFacade } from 'src/app/store/auth/auth.facade';
import { AppAuthService } from 'src/app/_services/auth.service';

@Component({
    selector: 'app-non-home-nav-bar',
    templateUrl: './home-nav-bar.component.html',
    styleUrls: ['./non-home-nav-bar.component.scss']
})
export class NonHomeNavBarComponent implements OnInit {
    @Input()
    logoImagePath: string;

    userName: string;
    // isAuthticated$ = this.authFacade.isAuthticated$;
    userTokenExists: boolean;

    constructor(private router: Router, private appAuthService: AppAuthService, private authFacade: AuthFacade) {
        this.authFacade.userName$.subscribe(name => this.userName = name);
        this.authFacade.isAuthticated$.subscribe(
            res => {
                if (!res) {
                    this.userName = null;
                    this.userTokenExists = false;
                }
            }
        );
    }

    ngOnInit(): void {
        const user: any = JSON.parse(localStorage.getItem('user'));
        if (user) {
            this.userTokenExists = true;
            this.userName = user.firstName;
        }
    }

    logOut(): void {
        this.userName = null;
        this.userTokenExists = false;
        this.appAuthService.logout();
        this.router.navigate(['/home']);
    }

    onAddProperty(): void {
        if (localStorage.getItem('user')) {
            this.router.navigate(['/dashboard/property/add']);
        } else {
            // need to login to add a property if unlogin
            this.router.navigate(['login']);
        }
    }
}

