export class MenuId {
    static readonly adminPropertyList = 'admin-property-list';
    static readonly agentPropertyList = 'agent-property-list';
    static readonly ownerPropertyList = 'owner-property-list';

    static readonly adminBookingList = 'admin-booking-list';
    static readonly agentBookingList = 'agent-booking-list';
    static readonly ownerBookingList = 'owner-booking-list';
    static readonly tenantBookingList = 'tenant-booking-list';

    static readonly adminRequestList = 'admin-request-list';
    static readonly agentRequestList = 'agent-request-list';
    static readonly ownerRequestList = 'owner-request-list';
    static readonly tenantRequestList = 'tenant-request-list';

    static readonly adminViewingTimeList = 'admin-view-time-list';
    static readonly agentViewingTimeList = 'agent-view-time-list';
    static readonly ownerViewingTimeList = 'owner-view-time-list';
    static readonly tenantViewingTimeList = 'tenant-view-time-list';
}
