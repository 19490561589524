import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

// import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { NoErrorResponse } from 'src/app/_helpers/no-error-response';
import { CustomErrorResponse } from 'src/app/_helpers/custom-error-response';
import { AppAuthService } from 'src/app/_services/auth.service';
import { AppAlertService } from 'src/app/_services/app-alert.service';
import { getUserId } from 'src/app/utls/common-functions';

/**
 * We use this HttpInterceptor (as server side error interceptor) to handle with
 * 1. 401 Unauthorized error
 * 2. customized application exception CustomErrorResponse object
 */
@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    // constructor(private authenticationService: AuthenticationService) { }
    constructor(private router: Router, private appAuthService: AppAuthService, private appAlertService: AppAlertService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                // 401 Unauthorized
                if (err.status === 401) {
                    const loginUserId = getUserId();
                    this.appAuthService.logout();
                    this.router.navigate(['/login']);
                    if (loginUserId) {
                        this.appAlertService.error('Your connection is timed out or you do not login. Please login again.');
                        return throwError(new NoErrorResponse());
                    }
                }

                // For server side error of 500, sometimes err.error is null, sometimes it is ProgressEvent.
                // We also use it for our customized error in json.
                if (err.status === 500 && err.error && !(err.error instanceof ProgressEvent)) {
                    const customJsonError = JSON.parse(err.error);
                    // To handle custom error response from API server
                    // If statusCode and message and description in err.error exist, we think it is a customized error from API.
                    if (customJsonError.statusCode && customJsonError.title && customJsonError.description) {
                        const error = new CustomErrorResponse();
                        Object.assign(error, customJsonError);
                        // throw CustomErrorResponse object for app to use
                        return throwError(error);
                    }
                }

                return throwError(err);
            })
        );
    }
}
