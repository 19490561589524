import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * To handle with fetching error message in string type from Error/HttpErrorResponse object
 */
@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    constructor() { }

    getClientErrorMessage(error: Error): string {
        return error.message ?
            error.message :
            error.toString();
    }

    getClientStack(error: Error): string {
        return error.stack;
    }

    getServerErrorMessage(error: HttpErrorResponse): string {
        return navigator.onLine ?
            `Message: ${error.message}` :
            'No Internet Connection';
    }
}
