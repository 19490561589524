<section id="navBar">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-lg-4 m-0">
        <a class="navbar-brand" routerLink="/home"><img [src]="logoImagePath" alt="home" width="38%" height="auto"></a>
      </div>
      <div class="col-md-12 col-sm-12 col-lg-8 m-0">
        <nav class="navbar navbar-expand-md bg-dark navbar-dark">
          <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav f18">
              <li class="nav-item">
                <a class="nav-link" routerLink="/about-us">About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/contact-us">Contact Us</a>
              </li>
              <!--
              <li class="nav-item nav-link" (click)="onAddProperty()">
                <i class="fas fa-pencil-alt"></i> <span> List your property</span>
              </li>
            -->
            </ul>
          </div>

          <!-- Display Login/Register hyperlinks if not authenticated -->
          <div class="log-reg" *ngIf="!userTokenExists">
            <a routerLink="/login"> <i class="fas fa-user"></i> <span> Login </span></a>
            <span class="vertical-bar">|</span>
            <a routerLink='/register'> <i class="fas fa-lock"></i> <span> Register </span></a>
          </div>

          <!-- Display "Welcome" label and login user menus if authenticated -->
          <div class="profile" *ngIf="userTokenExists">
            <!-- Welcome label -->
            <span class="color-gray">Welcome: </span>
            <!-- circle icon for user -->
            <a routerLink="/" class="link"><i class="far fa-user-circle"></i></a>
            <!-- use name and his dropdown menus -->
            <div class="dropdown">
              <div class="username-style">
                <!-- user name label -->
                <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                  {{userName}}
                </a>
                <!-- dropdown -->
                <span class="dropdown-menu">
                  <!-- dropdown menu: Dashboard -->
                  <a class="dropdown-item" routerLink="/dashboard/profile-info">Dashboard</a>
                  <!-- dropdown menu: Log Out -->
                  <a class="dropdown-item" href="javascript:void(0)" (click)="logOut()">Log Out</a>
                </span>
              </div>
            </div>
          </div>

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
        </nav>

      </div>

    </div>
  </div>
</section>
