import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
    isLoading = false;

    constructor(private spinnerService: SpinnerService) {
        this.spinnerService.isLoading$.subscribe((shown) => {
            this.isLoading = shown;
        });
    }

    ngOnInit(): void {
    }
}
