import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { IAppConfig } from 'src/app/app-config.model';

@Injectable({
    providedIn: 'root'
})
export class ApiHttpService {
    apiControllerRoot: string;
    propertyImageRootUrl: string;

    constructor(@Inject(BASE_API_URL_TOKEN) public appConfig: IAppConfig, private http: HttpClient) {
        this.apiControllerRoot = `${this.appConfig.env.apiUrl}/api`;
        this.propertyImageRootUrl = `${this.appConfig.env.apiUrl}/images`;
    }

    /**
     * To get data by using GET HTTP command in ID
     * @param url API control name for get HTTP requirement command
     */
    get(url: string): Observable<any> {
        const strUrl = `${this.apiControllerRoot}/${url}`;
        // console.log('GET:', strUrl);
        return this.http.get<any>(strUrl);
    }

    getTextResponse(url: string): Observable<any> {
        const strUrl = `${this.apiControllerRoot}/${url}`;
        // console.log('GET (text returned):', strUrl);
        return this.http.get<any>(strUrl, { responseType: 'text' as 'json' });
    }

    getImageBlob(url: string): Observable<any> {
        const strUrl = `${this.propertyImageRootUrl}/${url}`;
        return this.http.get(strUrl, { responseType: 'blob' });
    }

    getWithParams(url: string, params: any): Observable<any> {
        const strUrl = `${this.apiControllerRoot}/${url}`;
        // console.log('GET with params:', strUrl);
        return this.http.get<any>(strUrl, { params: params as HttpParams });
    }

    getWithBodyParams(url: string, params: any): Observable<any> {
        const strUrl = `${this.apiControllerRoot}/${url}`;
        return this.http.post<any>(strUrl, params );
    }

    /**
     * To post data by using POST HTTP command
     * @param data posting data
     * @param url API control name for post HTTP requirement command
     */
    create(url: string, entity: any): Observable<any> {
        const strUrl = `${this.apiControllerRoot}/${url}`;
        console.log('POST:', strUrl);
        return this.http.post(strUrl, entity);
    }

    update(url: string, entity: any): Observable<any> {
        const strUrl = `${this.apiControllerRoot}/${url}`;
        console.log('PUT:', strUrl);
        return this.http.put<any>(strUrl, entity);
    }

    save(url: string, entity: any): Observable<any> {
        const strUrl = `${this.apiControllerRoot}/${url}`;
        console.log('POST:', strUrl);
        return this.http.post(strUrl, entity);
    }

    delete(url: string): Observable<any> {
        const strUrl = `${this.apiControllerRoot}/${url}`;
        return this.http.delete<any>(strUrl);
    }
}
