import { Subscription } from 'rxjs';
import { CommonLayoutService } from './common-layout.service';
import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-common-layout',
    templateUrl: './common-layout.component.html',
    styleUrls: ['./common-layout.component.scss']
})
export class CommonLayoutComponent implements OnInit, OnDestroy, AfterViewChecked {

    title: string;
    unsubscribe: Subscription;

    constructor(public commonLayoutService: CommonLayoutService, private cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.unsubscribe = this.commonLayoutService.title$.subscribe(val => this.title = val);
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe.unsubscribe();
    }
}
