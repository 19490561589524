/**
 * Name
 */
export const featureName = 'auth';

/**
 * State
 */
export interface IState {
    isAuthenticated: boolean;
    role: string[];
    displayedUserName: string;
}

/**
 * Initial state
 */
export const initialState: IState = {
    isAuthenticated: false,
    role: null,
    displayedUserName: null
};
