import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonLayoutService {
    title$ = new Subject<string>();

    constructor() { }

    setTitle(title: string): void {
        this.title$.next(title);
    }
}
