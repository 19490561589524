<app-non-home-nav-bar logoImagePath="assets/logo/new_home_logo_2.png"></app-non-home-nav-bar>

<section>
  <hr style="background-color: darkgray;">
  <!-- <div class="banner-img">
    <div class="container-fluid banner-background-color">
      <div class="container">
        <div class="row">
          <div class="banner-title">
            {{title}}
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <router-outlet></router-outlet>
</section>
