/**
 * these constants are constants in database
 */
export class Role {
    static readonly tenant = 'Tenant';
    static readonly propertyOwner = 'Property Owner';
    static readonly agent = 'Regional Agent';
    static readonly admin = 'Administrator';
    static readonly winz = 'WINZ';
}

export const roles: string[] = [
    Role.tenant,
    Role.propertyOwner,
    Role.agent,
    Role.winz,
];
