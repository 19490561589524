import { Action, createReducer, on } from '@ngrx/store';
import { IState, initialState } from './auth.state';
import { login, logout, changeUserProfile } from './auth.action';

const authReducer = createReducer(
    initialState,
    on(login, (state, { roles }) => {
        return { ...state, isAuthenticated: true, role: roles };
    }),

    on(logout, state => {
        return { ...state, isAuthenticated: false, role: null, displayedUserName: null };
    }),

    on(changeUserProfile, (state, { payload }) => {
        return { ...state, displayedUserName: payload };
    }),
);

/**
 * Reducer
 */
export function reducer(state: IState, action: Action): IState {
    return authReducer(state, action);
}
